/*
 * Bootstrap variable overrides:
 */

// Colors:
$primary: #375E4D;
$secondary: #955304;
$warning: #949C15;
$danger: #7A2713;
$dark: #141414;
$body-bg: #AACDBE;

// Rounded buttons
$btn-border-radius: 10rem;
$btn-border-radius-sm: 10rem;
$btn-border-radius-lg: 10rem;
$btn-padding-x: 2rem;
$btn-padding-y: 0.875rem;

$font-family-sans-serif: Poppins, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif !default;
$font-family-base: $font-family-sans-serif  !default;
$btn-font-weight: 600;

// Modals
$modal-dialog-margin: 4rem;
$modal-dialog-margin-y-sm-up: 6rem;
$modal-backdrop-opacity: 0.75;

@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

/*
 * Other global variables
 */
$sage: #AACDBE;
$yellow: #FBC687;
$beige: #F4F7C5;
$orange: #EA907A;

$sage-light: #ddebe5;
$yellow-light: #fce4c7;
$beige-light: #eef1c0;
$orange-light: #caa9a1;

$sage-dark: #375E4D;
$yellow-dark: #955304;
$beige-dark: #949C15;
$orange-dark: #7A2713;
