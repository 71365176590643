@import "custom-bootstrap";

// Global style rules
.rotate-180 {
  transform: rotate(180deg);
}

.center-image {
  background-position: center;
  background-size: cover;
}

.min-30 {
  min-height: 30vh;
}

[id] {
  scroll-margin-top: 60px;
}


// Font
@font-face {
  font-family: "Poppins";
  src: local("Poppins-ExtraBold"), url(../assets/fonts/Poppins/Poppins-ExtraBold.ttf) format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins-ExtraBoldItalic"), url(../assets/fonts/Poppins/Poppins-ExtraBoldItalic.ttf) format("truetype");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins-Bold"), url(../assets/fonts/Poppins/Poppins-Bold.ttf) format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins-BoldItalic"), url(../assets/fonts/Poppins/Poppins-BoldItalic.ttf) format("truetype");
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins-SemiBold"), url(../assets/fonts/Poppins/Poppins-SemiBold.ttf) format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins-SemiBoldItalic"), url(../assets/fonts/Poppins/Poppins-SemiBoldItalic.ttf) format("truetype");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins-Regular"), url(../assets/fonts/Poppins/Poppins-Regular.ttf) format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins-Italic"), url(../assets/fonts/Poppins/Poppins-Italic.ttf) format("truetype");
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins-Light"), url(../assets/fonts/Poppins/Poppins-Light.ttf) format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins-LightItalic"), url(../assets/fonts/Poppins/Poppins-LightItalic.ttf) format("truetype");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins-ExtraLight"), url(../assets/fonts/Poppins/Poppins-ExtraLight.ttf) format("truetype");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins-ExtraLightItalic"), url(../assets/fonts/Poppins/Poppins-ExtraLightItalic.ttf) format("truetype");
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

// Headings font weight
h3,
.h3,
h2,
.h2,
h1,
.h1 {
  font-weight: 600;
}

h6,
.h6,
h5,
.h5,
h4,
.h4 {
  font-weight: 600;
}
